/**
 * @generated SignedSource<<d017c9d51923389e815d3dabebe419d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateWebFormSubmissionInput = {
  answers?: ReadonlyArray<WebFormAnswerInput> | null;
  contentUsageId?: string | null;
  webFormRevisionId: string;
};
export type WebFormAnswerInput = {
  body?: string | null;
  isSkipped?: boolean | null;
  selectedOptions?: ReadonlyArray<string> | null;
  webFormQuestionId: string;
};
export type PollOptionsMutation$variables = {
  input: CreateWebFormSubmissionInput;
};
export type PollOptionsMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly id: string;
      readonly webFormRevision: {
        readonly questions: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly " $fragmentSpreads": FragmentRefs<"PollOptionsFragment">;
            };
          }>;
        };
      };
    } | null;
  };
};
export type PollOptionsMutation = {
  response: PollOptionsMutation$data;
  variables: PollOptionsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PollOptionsMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "CreateWebFormSubmissionResponse",
        "kind": "LinkedField",
        "name": "createWebFormSubmission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WebFormSubmission",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "WebFormRevision",
                "kind": "LinkedField",
                "name": "webFormRevision",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "WebFormQuestionNodeConnection",
                    "kind": "LinkedField",
                    "name": "questions",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WebFormQuestionNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "WebFormQuestion",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "PollOptionsFragment"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "questions(first:1)"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PollOptionsMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "CreateWebFormSubmissionResponse",
        "kind": "LinkedField",
        "name": "createWebFormSubmission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WebFormSubmission",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "WebFormRevision",
                "kind": "LinkedField",
                "name": "webFormRevision",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "WebFormQuestionNodeConnection",
                    "kind": "LinkedField",
                    "name": "questions",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WebFormQuestionNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "WebFormQuestion",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "webFormRevisionId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "WebFormQuestionOption",
                                "kind": "LinkedField",
                                "name": "options",
                                "plural": true,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "label",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "WebFormAnswerNodeConnection",
                                "kind": "LinkedField",
                                "name": "answers",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "WebFormAnswerNodeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "WebFormAnswer",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v2/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "webFormQuestionId",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "webFormSubmissionId",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "selectedOptions",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "userId",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "user",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "fullName",
                                                "storageKey": null
                                              },
                                              (v2/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "firstName",
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "lastName",
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "avatar",
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "isTest",
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "questions(first:1)"
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b07df495a7b97e6d84a5526d63c05c58",
    "id": null,
    "metadata": {},
    "name": "PollOptionsMutation",
    "operationKind": "mutation",
    "text": "mutation PollOptionsMutation(\n  $input: CreateWebFormSubmissionInput!\n) {\n  response: createWebFormSubmission(input: $input) {\n    node {\n      id\n      webFormRevision {\n        questions(first: 1) {\n          edges {\n            node {\n              ...PollOptionsFragment\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment PollOptionsFragment on WebFormQuestion {\n  id\n  type\n  webFormRevisionId\n  options {\n    id\n    label\n  }\n  answers {\n    edges {\n      node {\n        id\n        webFormQuestionId\n        webFormSubmissionId\n        selectedOptions\n        userId\n        user {\n          fullName\n          id\n          firstName\n          lastName\n          ...ProfileAvatarFragment\n          ...ProfileListModalListItemFragment\n        }\n      }\n    }\n  }\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n\nfragment ProfileListModalListItemFragment on User {\n  id\n  firstName\n  lastName\n  fullName\n  ...ProfileAvatarFragment\n}\n"
  }
};
})();

(node as any).hash = "917d423805f0af7ca0cd00f1e525f359";

export default node;
