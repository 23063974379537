/**
 * @generated SignedSource<<15959fbcdeeba9515073348493cf7eb4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type WebFormQuestionType = "link" | "multiple_select" | "ranking" | "rating" | "single_select" | "written_answer" | "%future added value";
export type WebFormTemplate = "poll" | "quiz" | "survey" | "%future added value";
export type CreateWebFormInput = {
  contentId?: string | null;
  organizationId: string;
  questions?: ReadonlyArray<WebFormQuestionInput> | null;
  settings?: UpdateWebFormSettingsInput | null;
  template: WebFormTemplate;
};
export type UpdateWebFormSettingsInput = {
  allowSubmissionDeletion?: boolean | null;
  durationSeconds?: number | null;
  endDate?: string | null;
  membersSeeAllResults?: boolean | null;
};
export type WebFormQuestionInput = {
  answerKey?: WebFormQuestionAnswerKeyInput | null;
  isRequired?: boolean | null;
  options?: ReadonlyArray<WebFormQuestionOptionInput> | null;
  richEditorBody: string;
  type: WebFormQuestionType;
};
export type WebFormQuestionOptionInput = {
  id: string;
  label: string;
};
export type WebFormQuestionAnswerKeyInput = {
  correctIds: ReadonlyArray<string>;
  feedback?: string | null;
};
export type PollWebFormEditorMutation$variables = {
  input: CreateWebFormInput;
};
export type PollWebFormEditorMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly id: string;
    } | null;
  };
};
export type PollWebFormEditorMutation = {
  response: PollWebFormEditorMutation$data;
  variables: PollWebFormEditorMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateWebFormResponse",
    "kind": "LinkedField",
    "name": "createWebForm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WebForm",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PollWebFormEditorMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PollWebFormEditorMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "439dbfbea75f2323573560314289a1a4",
    "id": null,
    "metadata": {},
    "name": "PollWebFormEditorMutation",
    "operationKind": "mutation",
    "text": "mutation PollWebFormEditorMutation(\n  $input: CreateWebFormInput!\n) {\n  response: createWebForm(input: $input) {\n    node {\n      id\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1990f181ce13e98ca46132cc3b324ab3";

export default node;
