/**
 * @generated SignedSource<<49d2875ad7e9ace6b5500fd6cc8bf9bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WebFormQuestionType = "link" | "multiple_select" | "ranking" | "rating" | "single_select" | "written_answer" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type webFormSubmissionsUtils_usePaginatedQuestionsPaginationFragment$data = {
  readonly id: string;
  readonly questions: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly answerKey: {
          readonly correctIds: ReadonlyArray<string>;
          readonly feedback: string | null;
        } | null;
        readonly answers: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly body: string | null;
              readonly id: string;
              readonly isCorrect: boolean | null;
              readonly isSkipped: boolean;
              readonly result: {
                readonly correctIds: ReadonlyArray<string>;
                readonly incorrectSelections: ReadonlyArray<string>;
              } | null;
              readonly selectedOptions: ReadonlyArray<string> | null;
              readonly webFormQuestionId: string;
            };
          }>;
          readonly totalCount: number;
        };
        readonly id: string;
        readonly isRequired: boolean;
        readonly options: ReadonlyArray<{
          readonly id: string;
          readonly label: string;
        }> | null;
        readonly ordering: number;
        readonly richEditorBody: string;
        readonly type: WebFormQuestionType;
      };
    }>;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
    readonly totalCount: number;
  };
  readonly " $fragmentType": "webFormSubmissionsUtils_usePaginatedQuestionsPaginationFragment";
};
export type webFormSubmissionsUtils_usePaginatedQuestionsPaginationFragment$key = {
  readonly " $data"?: webFormSubmissionsUtils_usePaginatedQuestionsPaginationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"webFormSubmissionsUtils_usePaginatedQuestionsPaginationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "questions"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correctIds",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "contentUsageId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./usePaginatedQuestionsPaginationFragment.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "webFormSubmissionsUtils_usePaginatedQuestionsPaginationFragment",
  "selections": [
    (v1/*: any*/),
    {
      "alias": "questions",
      "args": null,
      "concreteType": "WebFormQuestionNodeConnection",
      "kind": "LinkedField",
      "name": "__usePaginatedQuestionsPaginationFragment_questions_connection",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "WebFormQuestionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "WebFormQuestion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ordering",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "richEditorBody",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isRequired",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WebFormQuestionOption",
                  "kind": "LinkedField",
                  "name": "options",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "label",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WebFormQuestionAnswerKey",
                  "kind": "LinkedField",
                  "name": "answerKey",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "feedback",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "contentUsageId",
                      "variableName": "contentUsageId"
                    }
                  ],
                  "concreteType": "WebFormAnswerNodeConnection",
                  "kind": "LinkedField",
                  "name": "answers",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "WebFormAnswerNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "WebFormAnswer",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "body",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "selectedOptions",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "webFormQuestionId",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isSkipped",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isCorrect",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "WebFormAnswerResult",
                              "kind": "LinkedField",
                              "name": "result",
                              "plural": false,
                              "selections": [
                                (v3/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "incorrectSelections",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfoObjectType",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WebFormRevision",
  "abstractKey": null
};
})();

(node as any).hash = "dcb4dae44915c3fbae0790d72efc47d7";

export default node;
