import { OrderDirection } from "@/admin/content-library/__generated__/AdminContentLibraryListTablePaginationQuery.graphql"
import { QuizSubmissionEditorFormState } from "@/content-usage/drawer/quizzes/submission-editor/QuizSubmissionEditor"
import { QuizSubmissionEditorFormMutation } from "@/content-usage/drawer/quizzes/submission-editor/__generated__/QuizSubmissionEditorFormMutation.graphql"
import FormStore from "@/core/form/store/FormStore"
import RelayEnvironment from "@/relay/RelayEnvironment"
import { GlobalID, NodeFromConnection } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import {
  WebFormSubmissionsOrderByField,
  WebFormSubmissionsOrderByInput,
} from "@/web-form/utils/__generated__/usePaginatedSubmissionsPaginationFragment.graphql"
import { webFormSubmissionsUtils_useCountQuery } from "@/web-form/utils/__generated__/webFormSubmissionsUtils_useCountQuery.graphql"
import { webFormSubmissionsUtils_usePaginatedQuestionsPaginationFragment$key } from "@/web-form/utils/__generated__/webFormSubmissionsUtils_usePaginatedQuestionsPaginationFragment.graphql"
import { webFormSubmissionsUtils_usePaginatedQuestionsQuery } from "@/web-form/utils/__generated__/webFormSubmissionsUtils_usePaginatedQuestionsQuery.graphql"
import {
  webFormSubmissionsUtils_usePaginatedSubmissionsPaginationFragment$data,
  webFormSubmissionsUtils_usePaginatedSubmissionsPaginationFragment$key,
} from "@/web-form/utils/__generated__/webFormSubmissionsUtils_usePaginatedSubmissionsPaginationFragment.graphql"
import {
  webFormSubmissionsUtils_usePaginatedSubmissionsQuery,
  webFormSubmissionsUtils_usePaginatedSubmissionsQuery$variables,
} from "@/web-form/utils/__generated__/webFormSubmissionsUtils_usePaginatedSubmissionsQuery.graphql"
import {
  DiscoTableFilterByOption,
  DiscoTableUtils,
} from "@disco-ui/table/discoTableUtils"
import { subHours, subMonths, subWeeks } from "date-fns"
import { useEffect } from "react"
import {
  commitLocalUpdate,
  graphql,
  useLazyLoadQuery,
  usePaginationFragment,
  useSubscribeToInvalidationState,
} from "react-relay"

export namespace WebFormSubmissionsUtils {
  /**
   *
   *
   * TYPES
   *
   *
   */

  export type OrderByOption = {
    field: WebFormSubmissionsOrderByField
    direction?: OrderDirection
    title?: string
  }

  type FilterByAllOption = {
    title: "All"
    value: null
    transform: null
  }

  type FilterByOption<TOption extends DiscoTableFilterByOption, TValue> = {
    title: string
    value: TValue
    selectedOption: TOption
    options: TOption[]
  }

  type FilterByOptions =
    | FilterByOption<
        | {
            title: "Incomplete" | "Complete" | "Passed" | "Failed"
            value: "complete" | "incomplete" | "passed" | "failed"
            transform: boolean
          }
        | FilterByAllOption,
        "status"
      >
    | FilterByOption<
        | {
            title:
              | "80% and up"
              | "60% - 79%"
              | "40% - 59%"
              | "20% - 39%"
              | "Less than 20%"
            value: "80+" | "60-79" | "40-59" | "20-39" | "20-"
            transform: { upperBound: number | null; lowerBound: number | null }
          }
        | FilterByAllOption,
        "result"
      >
    | FilterByOption<
        | {
            title:
              | "In the last 24 hours"
              | "In the last 1 week"
              | "In the last 1 month"
              | "In the last 3 months"
            value: "24h" | "1w" | "1m" | "3m"
            transform: (d: Date) => Date
          }
        | FilterByAllOption,
        "completed"
      >

  export interface ToolbarState {
    orderBy: (WebFormSubmissionsOrderByInput & { title?: string }) | null
    filterBy: {
      [K in FilterByOptions["value"]]: FilterByOptions["selectedOption"] | null
    }
    search: string | null
    submissionIds: GlobalID[] | null
  }

  export type ToolbarStateHandlers = DiscoTableUtils.ToolbarStateHandlers<ToolbarState>

  export type WebFormSubmissionData = NodeFromConnection<
    webFormSubmissionsUtils_usePaginatedSubmissionsPaginationFragment$data["submissions"]
  >

  /**
   *
   *
   * CONSTANTS
   *
   *
   */

  export const ORDER_BY_OPTIONS: OrderByOption[] = [
    { title: "User Name", field: "user_name" },
    { title: "Result", field: "score" },
    { title: "Time Spent", field: "duration" },
    { title: "Completed On", field: "completed_at" },
  ]

  export function getFilterByOptions({
    isPassFail = false,
  }: {
    isPassFail: boolean
  }): FilterByOptions[] {
    return [
      {
        title: "Submission Status",
        value: "status",
        selectedOption: { title: "All", value: null, transform: null },
        options: isPassFail
          ? [
              { title: "All", value: null, transform: null },
              { title: "Passed", value: "passed", transform: true },
              { title: "Failed", value: "failed", transform: false },
              { title: "Incomplete", value: "incomplete", transform: false },
            ]
          : [
              { title: "Incomplete", value: "incomplete", transform: false },
              { title: "Complete", value: "complete", transform: true },
              { title: "All", value: null, transform: null },
            ],
      },
      {
        title: "Result",
        value: "result",
        selectedOption: { title: "All", value: null, transform: null },
        options: [
          {
            title: "80% and up",
            value: "80+",
            transform: { upperBound: null, lowerBound: 80 },
          },
          {
            title: "60% - 79%",
            value: "60-79",
            transform: { upperBound: 80, lowerBound: 60 },
          },
          {
            title: "40% - 59%",
            value: "40-59",
            transform: { upperBound: 60, lowerBound: 40 },
          },
          {
            title: "20% - 39%",
            value: "20-39",
            transform: { upperBound: 40, lowerBound: 20 },
          },
          {
            title: "Less than 20%",
            value: "20-",
            transform: { upperBound: 20, lowerBound: 0 },
          },
          { title: "All", value: null, transform: null },
        ],
      },
      {
        title: "Completed On",
        value: "completed",
        selectedOption: { title: "All", value: null, transform: null },
        options: [
          {
            title: "In the last 24 hours",
            value: "24h",
            transform: (d) => subHours(d, 24),
          },
          { title: "In the last 1 week", value: "1w", transform: (d) => subWeeks(d, 1) },
          {
            title: "In the last 1 month",
            value: "1m",
            transform: (d) => subMonths(d, 1),
          },
          {
            title: "In the last 3 months",
            value: "3m",
            transform: (d) => subMonths(d, 3),
          },
          { title: "All", value: null, transform: null },
        ],
      },
    ]
  }

  export const INITIAL_TOOLBAR_STATE: ToolbarState = {
    search: "",
    filterBy: {
      completed: null,
      status: null,
      result: null,
    },
    orderBy: null,
    submissionIds: null,
  }

  /**
   *
   *
   * HELPERS
   *
   *
   */

  export function handleFilterByFields(toolbarState?: ToolbarState) {
    const filterByOptions: Pick<
      webFormSubmissionsUtils_usePaginatedSubmissionsQuery$variables,
      | "completedAfter"
      | "percentageGreaterThanOrEqual"
      | "percentageLessThan"
      | "isComplete"
      | "isPassed"
      | "isFailed"
    > = {}

    if (!toolbarState) return filterByOptions

    if (toolbarState.filterBy.status) {
      if (toolbarState.filterBy.status.value === "passed") {
        filterByOptions.isPassed = true
      } else if (toolbarState.filterBy.status.value === "failed") {
        filterByOptions.isFailed = true
      } else if (typeof toolbarState.filterBy.status.transform === "boolean") {
        filterByOptions.isComplete = toolbarState.filterBy.status.transform
      }
    }

    if (
      toolbarState.filterBy.result &&
      typeof toolbarState.filterBy.result.transform === "object"
    ) {
      const { transform } = toolbarState.filterBy.result
      if (transform !== null && "lowerBound" in transform) {
        filterByOptions.percentageGreaterThanOrEqual = transform.lowerBound
        filterByOptions.percentageLessThan = transform.upperBound
      }
    }

    if (toolbarState.filterBy.completed) {
      if (typeof toolbarState.filterBy.completed.transform === "function") {
        filterByOptions.completedAfter = toolbarState.filterBy.completed
          .transform(new Date())
          .toISOString()
      }
    }

    return filterByOptions
  }

  export function resetSubmissionForm(
    form?: FormStore<QuizSubmissionEditorFormState, QuizSubmissionEditorFormMutation>
  ) {
    if (!form) return

    // Reset all the answers
    form.state.webFormSubmission.answers.forEach((a) => {
      a.selectedOptions?.clear()
      a.isSkipped = false
    })

    // Reset the active answer index to the start
    form.state.activeAnswerIndex = 0
  }

  /**
   *
   *
   * HOOKS
   *
   *
   */

  export function useToolbarState(
    initialToolbarState?: ToolbarState,
    initialActivePage?: number
  ) {
    const toolbar = DiscoTableUtils.useTableState<ToolbarState>({
      initialActivePage: initialActivePage ?? 0,
      initialToolbarState: initialToolbarState ?? INITIAL_TOOLBAR_STATE,
    })
    const hasFilters = Boolean(
      Object.values(toolbar.toolbarState.filterBy).filter(Boolean).length
    )
    return {
      ...toolbar,
      hasFilters,
      handleRemoveSubmissionIds: () => {
        toolbar.setToolbarState((prev) => ({
          ...prev,
          submissionIds: null,
        }))
      },
      handleAddSubmissionIds: (submissionIds: GlobalID[]) => {
        toolbar.setToolbarState((prev) => ({
          ...prev,
          submissionIds,
        }))
      },
    }
  }

  export function useCount({
    include = true,
    webFormId = "",
    contentUsageId,
    excludeUsageScope,
    uniqueByMember,
    /** when undefined, all submissions are counted, otherwise count complete or incomplete submissions exclusively  */
    completed,
    /** when undefined, all submissions are counted, otherwise count passed submissions exclusively  */
    passed,
    membersOnly,
  }: {
    contentUsageId?: GlobalID | null
    webFormId?: GlobalID
    include?: boolean
    excludeUsageScope?: boolean
    uniqueByMember?: boolean
    completed?: boolean
    passed?: boolean
    membersOnly?: boolean
  }) {
    const [{ node }, refetch] =
      Relay.useRefetchableQuery<webFormSubmissionsUtils_useCountQuery>(
        graphql`
          query webFormSubmissionsUtils_useCountQuery(
            $webFormId: ID!
            $include: Boolean!
            $contentUsageId: ID
            $excludeUsageScope: Boolean
            $uniqueByMember: Boolean
            $completed: Boolean
            $passed: Boolean
            $membersOnly: Boolean
          ) {
            node(id: $webFormId) @include(if: $include) {
              ... on WebForm {
                __typename
                id

                submissionsCount(
                  contentUsageId: $contentUsageId
                  excludeUsageScope: $excludeUsageScope
                  uniqueByMember: $uniqueByMember
                  completed: $completed
                  passed: $passed
                  membersOnly: $membersOnly
                )
              }
            }
          }
        `,
        {
          include: include && Boolean(webFormId),
          webFormId,
          contentUsageId,
          excludeUsageScope: excludeUsageScope ?? !contentUsageId,
          uniqueByMember: uniqueByMember ?? false,
          completed: completed ?? null,
          passed: passed ?? null,
          membersOnly: membersOnly ?? null,
        },
        { fetchPolicy: "network-only" }
      )

    useSubscribeToInvalidationState(webFormId ? [webFormId] : [], () => {
      refetch({
        include,
        webFormId,
        contentUsageId,
        excludeUsageScope: excludeUsageScope ?? !contentUsageId,
        uniqueByMember: uniqueByMember ?? false,
        completed: completed ?? null,
        passed: passed ?? null,
      })
    })

    return Relay.narrowNodeType(node ?? null, "WebForm")?.submissionsCount ?? 0
  }

  export function invalidateWebForm({ webFormId }: { webFormId: GlobalID }) {
    commitLocalUpdate(RelayEnvironment, (store) => {
      const webForm = store.get(webFormId)
      webForm?.invalidateRecord()
    })
  }

  export function usePaginatedSubmissions(
    {
      usageId,
      revisionId = "",
      first = 10,
      include = true,
      membersOnly,
    }: {
      usageId?: GlobalID
      revisionId?: GlobalID
      first?: number
      include?: boolean
      membersOnly?: boolean
    },
    toolbarState?: ToolbarState
  ) {
    const { node } =
      useLazyLoadQuery<webFormSubmissionsUtils_usePaginatedSubmissionsQuery>(
        graphql`
          query webFormSubmissionsUtils_usePaginatedSubmissionsQuery(
            $revisionId: ID!
            $contentUsageId: ID
            $submissionIds: [ID!]
            $include: Boolean!
            $orderBy: WebFormSubmissionsOrderByInput
            $search: String
            $isComplete: Boolean
            $completedAfter: DateTime
            $percentageGreaterThanOrEqual: Float
            $percentageLessThan: Float
            $isPassed: Boolean
            $isFailed: Boolean
            $membersOnly: Boolean
            $first: Int
            $after: String
            $last: Int
            $before: String
          ) {
            node(id: $revisionId) @include(if: $include) {
              ... on WebFormRevision {
                id
                __typename
                ...webFormEditorUtils_getFormStateFromRevisionFragment @relay(mask: false)
                ...webFormSubmissionsUtils_usePaginatedSubmissionsPaginationFragment
                  @arguments(
                    contentUsageId: $contentUsageId
                    submissionIds: $submissionIds

                    orderBy: $orderBy
                    search: $search
                    isComplete: $isComplete
                    completedAfter: $completedAfter
                    percentageGreaterThanOrEqual: $percentageGreaterThanOrEqual
                    percentageLessThan: $percentageLessThan
                    isPassed: $isPassed
                    isFailed: $isFailed
                    membersOnly: $membersOnly

                    first: $first
                    after: $after
                    last: $last
                    before: $before
                  )
              }
            }
          }
        `,
        {
          revisionId,
          contentUsageId: usageId,
          submissionIds: toolbarState?.submissionIds,
          first,
          include: Boolean(revisionId && usageId) && include,
          membersOnly,
        },
        {
          fetchPolicy: "store-and-network",
        }
      )

    const revision = Relay.narrowNodeType(node ?? null, "WebFormRevision")

    const pagination = usePaginationFragment<
      webFormSubmissionsUtils_usePaginatedSubmissionsQuery,
      webFormSubmissionsUtils_usePaginatedSubmissionsPaginationFragment$key
    >(
      graphql`
        fragment webFormSubmissionsUtils_usePaginatedSubmissionsPaginationFragment on WebFormRevision
        @refetchable(queryName: "usePaginatedSubmissionsPaginationFragment")
        @argumentDefinitions(
          contentUsageId: { type: "ID" }

          submissionIds: { type: "[ID!]" }
          orderBy: { type: "WebFormSubmissionsOrderByInput" }
          search: { type: "String" }
          isComplete: { type: "Boolean" }
          completedAfter: { type: "DateTime" }
          percentageGreaterThanOrEqual: { type: "Float" }
          percentageLessThan: { type: "Float" }
          isPassed: { type: "Boolean" }
          isFailed: { type: "Boolean" }
          membersOnly: { type: "Boolean" }

          first: { type: "Int" }
          after: { type: "String" }
          last: { type: "Int" }
          before: { type: "String" }
        ) {
          submissions(
            contentUsageId: $contentUsageId
            submissionIds: $submissionIds

            orderBy: $orderBy
            search: $search
            isComplete: $isComplete
            completedAfter: $completedAfter
            percentageGreaterThanOrEqual: $percentageGreaterThanOrEqual
            percentageLessThan: $percentageLessThan
            isPassed: $isPassed
            isFailed: $isFailed
            membersOnly: $membersOnly

            first: $first
            after: $after
            last: $last
            before: $before
          ) @connection(key: "webFormQueryUtils_admin_submissions") {
            __id
            totalCount
            edges {
              node {
                ...webFormQueryUtils_submissionFragment @relay(mask: false)
                ...webFormSubmissionsUtils_submissionUserFragment @relay(mask: false)
              }
            }
          }
        }
      `,
      revision
    )

    useEffect(() => {
      pagination.refetch({
        revisionId,
        contentUsageId: usageId,
        first,
        include: Boolean(revisionId && usageId) && include,
        search: toolbarState?.search,
        orderBy: toolbarState?.orderBy && {
          field: toolbarState.orderBy.field,
          direction: toolbarState.orderBy.direction,
        },
        ...handleFilterByFields(toolbarState),
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toolbarState])

    const submissions = Relay.connectionToArray(pagination.data?.submissions)
    return {
      pagination,
      submissions,
      revision,
      __id: pagination.data?.submissions?.__id,
    }
  }

  export function usePaginatedQuestions({
    usageId,
    revisionId = "",
    first = 10,
  }: {
    usageId?: GlobalID
    revisionId?: GlobalID
    first?: number
  }) {
    const { node } = useLazyLoadQuery<webFormSubmissionsUtils_usePaginatedQuestionsQuery>(
      graphql`
        query webFormSubmissionsUtils_usePaginatedQuestionsQuery(
          $id: ID!
          $contentUsageId: ID
          $first: Int
          $after: String
          $last: Int
          $before: String
        ) {
          node(id: $id) {
            ... on WebFormRevision {
              id
              ...webFormSubmissionsUtils_usePaginatedQuestionsPaginationFragment
                @arguments(
                  contentUsageId: $contentUsageId
                  first: $first
                  after: $after
                  last: $last
                  before: $before
                )
            }
          }
        }
      `,
      {
        id: revisionId,
        contentUsageId: usageId,
        first,
      }
    )

    return usePaginationFragment<
      webFormSubmissionsUtils_usePaginatedQuestionsQuery,
      webFormSubmissionsUtils_usePaginatedQuestionsPaginationFragment$key
    >(
      graphql`
        fragment webFormSubmissionsUtils_usePaginatedQuestionsPaginationFragment on WebFormRevision
        @refetchable(queryName: "usePaginatedQuestionsPaginationFragment")
        @argumentDefinitions(
          contentUsageId: { type: "ID" }

          first: { type: "Int" }
          after: { type: "String" }
          last: { type: "Int" }
          before: { type: "String" }
        ) {
          id
          questions(first: $first, after: $after, last: $last, before: $before)
            @connection(
              key: "usePaginatedQuestionsPaginationFragment_questions"
              filters: []
            ) {
            __id
            totalCount
            edges {
              cursor
              node {
                id
                ...webFormEditorUtils_questionFragment @relay(mask: false)
                answers(contentUsageId: $contentUsageId) {
                  totalCount
                  edges {
                    node {
                      ...webFormQueryUtils_answerFragment @relay(mask: false)
                    }
                  }
                }
              }
            }
            pageInfo {
              endCursor
              startCursor
              hasNextPage
              hasPreviousPage
            }
          }
        }
      `,
      node
    )
  }
}

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment webFormSubmissionsUtils_submissionUserFragment on WebFormSubmission {
    user {
      id
      fullName
      ...ProfileAvatarFragment
    }
  }
`
