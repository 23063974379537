/**
 * @generated SignedSource<<a3ad0eeed6ff1c98d6d1bd9f3a0d4dd7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WebFormQuestionType = "link" | "multiple_select" | "ranking" | "rating" | "single_select" | "written_answer" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type webFormFillerUtils_getFormStateFromRevisionFragment$data = {
  readonly id: string;
  readonly questions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly isRequired: boolean;
        readonly options: ReadonlyArray<{
          readonly id: string;
          readonly label: string;
        }> | null;
        readonly ordering: number;
        readonly richEditorBody: string;
        readonly type: WebFormQuestionType;
      };
    }>;
  };
  readonly webFormId: string;
  readonly " $fragmentType": "webFormFillerUtils_getFormStateFromRevisionFragment";
};
export type webFormFillerUtils_getFormStateFromRevisionFragment$key = {
  readonly " $data"?: webFormFillerUtils_getFormStateFromRevisionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"webFormFillerUtils_getFormStateFromRevisionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "webFormFillerUtils_getFormStateFromRevisionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "webFormId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WebFormQuestionNodeConnection",
      "kind": "LinkedField",
      "name": "questions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WebFormQuestionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WebFormQuestion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "richEditorBody",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ordering",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isRequired",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WebFormQuestionOption",
                  "kind": "LinkedField",
                  "name": "options",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "label",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WebFormRevision",
  "abstractKey": null
};
})();

(node as any).hash = "3121e6b37580e9651bf567240f72cba7";

export default node;
