const hh_mm_a_TIME_FORMAT_REGEX = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/
const YYYY_MM_DD_DATE_FORMAT_REGEX = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/

const DATE_FORMAT = {
  DEFAULT: "MMM d, yyyy",
  DEFAULT_WITHOUT_COMMA: "MMM d yyyy",
  DEFAULT_DATE_WITH_SHORT_TIME_FORMAT: "MMM d, yyyy — h:mm a",
  DEFAULT_DATE_WITH_SHORT_TIME_FORMAT_AT: "MMM d, yyyy @ h:mm a",
  DEFAULT_DATE_WITH_SHORT_TIME_FORMAT_WITH_OFFSET: "MMM d, yyyy — h:mm a z",
  DEFAULT_DATE_WITH_SHORT_TIME_FORMAT_WITH_OFFSET_AND_AT: "MMM d, yyyy @ h:mm a z",
  DEFAULT_FULL_MONTH_WITH_SHORT_TIME_FORMAT_WITH_OFFSET_AND_AT: "MMMM d, yyyy @ h:mm a z",
  DEFAULT_FULL_MONTH: "MMMM d, yyy",
  DEFAULT_FULL_MONTH_WITHOUT_YEAR: "MMMM d",
  DEFAULT_MONTH_AND_DAY: "MMM d",
  DATE_WITH_WEEK_DAY: "EEEE, MMMM d, yyyy",
  DATE_WITH_WEEK_DAY_WITHOUT_YEAR: "EEEE, MMMM d",
  DATE_WITH_SHORT_WEEK_DAY_WITHOUT_YEAR: "EEE, MMMM d",
  DATE_WITH_SHORT_TIME_FORMAT: "MMMM d — h:mm a",
  DATE_WITH_SHORT_TIME_FORMAT_AT: "MMM d @ h:mm a",
  REVERSE_DATE_WITH_WEEK_DAY: "EEEE, MMMM d yyyy",
  REVERSE_DATE_WITH_WEEK_DAY_WITH_TIME: "EEEE, MMMM d yyyy — h:mm a",
  REVERSE_DATE_WITH_WEEK_DAY_WITH_TIME_WITHOUT_YEAR: "EEEE, MMM d — h:mm a",
  REVERSE_DATE_WITH_WEEK_DAY_WITH_TIME_WITHOUT_YEAR_WITH_AT: "EEEE, MMM d @ h:mm a z",
  REVERSE_DATE_SHORT_WEEK_DAY_TIME_WITHOUT_YEAR: "EEE MMM d h:mm a",
  API_FORMAT: "yyyy-MM-dd",
  API_TIME_FORMAT: "HH:mm:ssxxx",
  DATETIME_API_FORMAT: "yyyy-MM-dd'T'HH:mm:ssxxx",
  SHORT_TIME_FORMAT: "h:mm a",
  LONG_TIME_FORMAT: "hh:mm a",
  TIME_WITH_ZONE: "h:mm a z",
  TIME_WITH_ZONE_INSIDE_PARENTHESES: "h:mm a (z)",
  /** 10:30am EDT */
  SHORT_TIME_WITH_ZONE: "h:mmaaaaa'm' z",
  UTC_OFFSET: "z",
  FULL_ZONE_NAME: "zzzz",
  MONTH: "MMM",
  FULL_MONTH: "MMMM",
  FULL_MONTH_AND_YEAR: "MMMM yyyy",
  DAY_LONG: "dd",
  WEEK_DAY: "EEEE",
  CARD_DATE: "MM/yyyy",
} as const

const SECOND_IN_MS = 1000
const MINUTE_IN_S = 60
const MINUTE_IN_MS = MINUTE_IN_S * SECOND_IN_MS
const HOUR_IN_MINS = 60
const HOUR_IN_S = HOUR_IN_MINS * MINUTE_IN_S
const DAY_IN_S = 86400
const DAY_IN_HRS = 24
const WEEK_IN_HRS = 168
const DAYS_IN_A_WEEK = 7

export {
  DATE_FORMAT,
  DAYS_IN_A_WEEK,
  DAY_IN_HRS,
  DAY_IN_S,
  HOUR_IN_MINS,
  HOUR_IN_S,
  MINUTE_IN_MS,
  MINUTE_IN_S,
  SECOND_IN_MS,
  WEEK_IN_HRS,
  YYYY_MM_DD_DATE_FORMAT_REGEX,
  hh_mm_a_TIME_FORMAT_REGEX,
}
