import { ProfileAvatarStackUser } from "@/user/common/avatar-stack/ProfileAvatarStack"
import LinkAnswerInput from "@/web-form/filler/answer-inputs/LinkAnswerInput"
import MultipleSelectAnswerInput from "@/web-form/filler/answer-inputs/MultipleSelectAnswerInput"
import RankingAnswerInput from "@/web-form/filler/answer-inputs/RankingAnswerInput"
import RatingAnswerInput from "@/web-form/filler/answer-inputs/RatingAnswerInput"
import SingleSelectAnswerInput from "@/web-form/filler/answer-inputs/SingleSelectAnswerInput"
import WrittenAnswerInput from "@/web-form/filler/answer-inputs/WrittenAnswerInput"
import { WebFormAnswerProps } from "@/web-form/filler/WebFormAnswer"
import { WebFormAnswerInput } from "@/web-form/utils/webFormFillerUtils"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

export type WebFormQuestionAnswers = {
  selectedOptions: readonly string[] | null
  user: ProfileAvatarStackUser
}[]

interface WebFormAnswerInputProps
  extends TestIDProps,
    Pick<WebFormAnswerProps, "variant" | "question"> {
  answer: WebFormAnswerInput
  disabled?: boolean
  allAnswers?: WebFormQuestionAnswers
}

function WebFormInput({
  testid = "WebFormInput",
  variant,
  question,
  answer,
  disabled,
  allAnswers,
}: WebFormAnswerInputProps) {
  switch (question.type) {
    case "single_select":
      if (!question.options)
        throw new Error("Options required for single select question")
      return (
        <SingleSelectAnswerInput
          options={question.options}
          answer={answer}
          testid={testid}
          variant={variant}
          disabled={disabled}
          allAnswers={allAnswers}
        />
      )
    case "multiple_select":
      if (!question.options)
        throw new Error("Options required for multiple select question")
      return (
        <MultipleSelectAnswerInput
          options={question.options}
          answer={answer}
          testid={testid}
          variant={variant}
          disabled={disabled}
          allAnswers={allAnswers}
        />
      )
    case "written_answer":
      return <WrittenAnswerInput answer={answer} testid={testid} disabled={disabled} />
    case "link":
      return <LinkAnswerInput answer={answer} testid={testid} disabled={disabled} />
    case "rating":
      if (!question.options) throw new Error("Options required for rating question")
      return (
        <RatingAnswerInput
          options={question.options}
          answer={answer}
          testid={testid}
          disabled={disabled}
        />
      )
    case "ranking":
      if (!question.options) throw new Error("Options required for ranking question")
      return (
        <RankingAnswerInput
          options={question.options}
          answer={answer}
          testid={testid}
          disabled={disabled}
        />
      )
    default:
      throw new Error(`Invalid question type '${question.type}'`)
  }
}

export default observer(WebFormInput)
