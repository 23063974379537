/**
 * @generated SignedSource<<8c4da2fa4d8fa2cd0e1a71818163325f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type WebFormQuestionType = "link" | "multiple_select" | "ranking" | "rating" | "single_select" | "written_answer" | "%future added value";
export type WebFormTemplate = "poll" | "quiz" | "survey" | "%future added value";
export type ValidateWebFormRevisionInput = {
  revision: CreateWebFormRevisionInput;
  webFormType: WebFormTemplate;
};
export type CreateWebFormRevisionInput = {
  contentUsageId?: string | null;
  questions: ReadonlyArray<WebFormQuestionInput>;
  webFormId: string;
};
export type WebFormQuestionInput = {
  answerKey?: WebFormQuestionAnswerKeyInput | null;
  isRequired?: boolean | null;
  options?: ReadonlyArray<WebFormQuestionOptionInput> | null;
  richEditorBody: string;
  type: WebFormQuestionType;
};
export type WebFormQuestionOptionInput = {
  id: string;
  label: string;
};
export type WebFormQuestionAnswerKeyInput = {
  correctIds: ReadonlyArray<string>;
  feedback?: string | null;
};
export type webFormEditorUtils_validateWebFormRevisionInputMutation$variables = {
  input: ValidateWebFormRevisionInput;
};
export type webFormEditorUtils_validateWebFormRevisionInputMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type webFormEditorUtils_validateWebFormRevisionInputMutation = {
  response: webFormEditorUtils_validateWebFormRevisionInputMutation$data;
  variables: webFormEditorUtils_validateWebFormRevisionInputMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BaseMutationResponse",
    "kind": "LinkedField",
    "name": "validateWebFormRevisionInput",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "webFormEditorUtils_validateWebFormRevisionInputMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "webFormEditorUtils_validateWebFormRevisionInputMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0f3491cc5b7b03f61e9d4438a795f80f",
    "id": null,
    "metadata": {},
    "name": "webFormEditorUtils_validateWebFormRevisionInputMutation",
    "operationKind": "mutation",
    "text": "mutation webFormEditorUtils_validateWebFormRevisionInputMutation(\n  $input: ValidateWebFormRevisionInput!\n) {\n  response: validateWebFormRevisionInput(input: $input) {\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a9cacc10a7e90cf507603ec53d014730";

export default node;
