import { useProductsAdminLearnMode } from "@/core/context/ProductsAdminLearnModeContext"
import { isAdminConsolePath } from "@/core/route/util/routeUtils"
import Relay from "@/relay/relayUtils"
import { usePermissionsFragment$key } from "@utils/hook/__generated__/usePermissionsFragment.graphql"
import { useFragment } from "react-relay"
import { useLocation } from "react-router-dom"
import { graphql } from "relay-runtime"

function usePermissions(
  entityKey: usePermissionsFragment$key | null | undefined
): Set<string> {
  const entity = useFragment<usePermissionsFragment$key>(
    graphql`
      fragment usePermissionsFragment on Node {
        # We need to query __typename or sometimes the server sometimes won't return
        # viewerPermissions on the node at all
        __typename
        ... on Organization {
          viewerPermissions
        }
        ... on Product {
          nonNullProductId: id
          viewerPermissions
          memberPermissions
        }
        ... on Event {
          nonNullProductId: productId
          viewerPermissions
          memberPermissions
        }
        ... on Comment {
          nullableProductId: productId
          viewerPermissions
          memberPermissions
        }
        ... on Feed {
          nullableProductId: productId
          viewerPermissions
          memberPermissions
        }
        ... on Post {
          nullableProductId: productId
          viewerPermissions
          memberPermissions
        }
        ... on ContentUsage {
          nullableProductIdString: productId
          viewerPermissions
          memberPermissions
        }
        ... on ChatChannel {
          nullableProductId: productId
          viewerPermissions
          memberPermissions
        }
        ... on Dashboard {
          nullableProductId: productId
          viewerPermissions
          memberPermissions
        }
        ... on AssignmentSubmission {
          nonNullProductId: productId
          viewerPermissions
          memberPermissions
        }
        ... on PathwayGroup {
          viewerPermissions
        }
        ... on BrainSearch {
          viewerPermissions
        }
      }
    `,
    entityKey ?? null
  )

  const { isViewerInLearnModeForProduct } = useProductsAdminLearnMode()
  const location = useLocation()
  const isOnAdminConsolePath = isAdminConsolePath(location.pathname)

  if (Relay.isNodeType(entity, "%other")) return new Set()

  if (
    Relay.isNodeType(entity, "Organization") ||
    Relay.isNodeType(entity, "PathwayGroup")
  ) {
    return new Set(entity?.viewerPermissions || [])
  }

  // Use the productId of each entity to determine if the entity is used in a product that we're in learn mode for.
  // If it is used in a product that we're in learn mode for, return the member level permissions.
  const productId =
    entity &&
    (("nonNullProductId" in entity && entity.nonNullProductId) ||
      ("nullableProductId" in entity && entity.nullableProductId) ||
      ("nullableProductIdString" in entity && entity.nullableProductIdString))

  if (!isOnAdminConsolePath && productId && isViewerInLearnModeForProduct(productId)) {
    // If the viewer is in learn mode for a product, return the member level permissions
    // so they can go through the course just like a member can.
    return new Set(entity?.memberPermissions || [])
  }
  return new Set(entity?.viewerPermissions || [])
}

export default usePermissions
