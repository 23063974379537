/**
 * @generated SignedSource<<0ce4e21b9b5997bc0eaf61523939fa3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type WebFormQuestionType = "link" | "multiple_select" | "ranking" | "rating" | "single_select" | "written_answer" | "%future added value";
export type WebFormSubmissionsOrderByField = "completed_at" | "created_at" | "duration" | "score" | "user_name" | "%future added value";
export type WebFormSubmissionsOrderByInput = {
  direction: OrderDirection;
  field: WebFormSubmissionsOrderByField;
};
export type webFormQueryUtils_useLearnerSubmissionsQuery$variables = {
  first?: number | null;
  include: boolean;
  includeSubmissions: boolean;
  orderBy?: WebFormSubmissionsOrderByInput | null;
  revisionId: string;
  submissionIds?: ReadonlyArray<string> | null;
  usageId?: string | null;
};
export type webFormQueryUtils_useLearnerSubmissionsQuery$data = {
  readonly node?: {
    readonly __typename: "WebFormRevision";
    readonly id: string;
    readonly questions: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly isRequired: boolean;
          readonly options: ReadonlyArray<{
            readonly id: string;
            readonly label: string;
          }> | null;
          readonly ordering: number;
          readonly richEditorBody: string;
          readonly type: WebFormQuestionType;
        };
      }>;
    };
    readonly viewerSubmissions?: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly answers: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly body: string | null;
                readonly id: string;
                readonly isCorrect: boolean | null;
                readonly isSkipped: boolean;
                readonly result: {
                  readonly correctIds: ReadonlyArray<string>;
                  readonly incorrectSelections: ReadonlyArray<string>;
                } | null;
                readonly selectedOptions: ReadonlyArray<string> | null;
                readonly webFormQuestionId: string;
              };
            }>;
          };
          readonly completedAt: string | null;
          readonly durationSeconds: number | null;
          readonly hasAnswerKey: boolean;
          readonly hasPassed: boolean | null;
          readonly id: string;
          readonly result: {
            readonly correctAnswers: ReadonlyArray<{
              readonly id: string;
            }>;
            readonly incorrectAnswers: ReadonlyArray<{
              readonly id: string;
            }>;
            readonly percentage: number;
            readonly score: number;
            readonly total: number;
          } | null;
          readonly webFormId: string;
          readonly webFormRevisionId: string;
        };
      }>;
      readonly totalCount: number;
    };
    readonly webFormId: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type webFormQueryUtils_useLearnerSubmissionsQuery = {
  response: webFormQueryUtils_useLearnerSubmissionsQuery$data;
  variables: webFormQueryUtils_useLearnerSubmissionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "include"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeSubmissions"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "revisionId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "submissionIds"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "usageId"
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "revisionId"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "webFormId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "WebFormQuestionNodeConnection",
  "kind": "LinkedField",
  "name": "questions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WebFormQuestionNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WebFormQuestion",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "richEditorBody",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ordering",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isRequired",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "WebFormQuestionOption",
              "kind": "LinkedField",
              "name": "options",
              "plural": true,
              "selections": [
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "label",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = [
  (v8/*: any*/)
],
v13 = {
  "condition": "includeSubmissions",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "contentUsageId",
          "variableName": "usageId"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "submissionIds",
          "variableName": "submissionIds"
        }
      ],
      "concreteType": "WebFormSubmissionNodeConnection",
      "kind": "LinkedField",
      "name": "viewerSubmissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WebFormSubmissionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WebFormSubmission",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v8/*: any*/),
                (v10/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "webFormRevisionId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "completedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "durationSeconds",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasAnswerKey",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPassed",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WebFormAnswerNodeConnection",
                  "kind": "LinkedField",
                  "name": "answers",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "WebFormAnswerNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "WebFormAnswer",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v8/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "body",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "selectedOptions",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "webFormQuestionId",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isSkipped",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isCorrect",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "WebFormAnswerResult",
                              "kind": "LinkedField",
                              "name": "result",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "correctIds",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "incorrectSelections",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WebFormSubmissionResult",
                  "kind": "LinkedField",
                  "name": "result",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "score",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "total",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "percentage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "WebFormAnswer",
                      "kind": "LinkedField",
                      "name": "correctAnswers",
                      "plural": true,
                      "selections": (v12/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "WebFormAnswer",
                      "kind": "LinkedField",
                      "name": "incorrectAnswers",
                      "plural": true,
                      "selections": (v12/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "webFormQueryUtils_useLearnerSubmissionsQuery",
    "selections": [
      {
        "condition": "include",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v13/*: any*/)
                ],
                "type": "WebFormRevision",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v6/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "webFormQueryUtils_useLearnerSubmissionsQuery",
    "selections": [
      {
        "condition": "include",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v8/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v13/*: any*/)
                ],
                "type": "WebFormRevision",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "4ce3761403491cc42233ca7a9de58c53",
    "id": null,
    "metadata": {},
    "name": "webFormQueryUtils_useLearnerSubmissionsQuery",
    "operationKind": "query",
    "text": "query webFormQueryUtils_useLearnerSubmissionsQuery(\n  $revisionId: ID!\n  $usageId: ID\n  $submissionIds: [ID!]\n  $orderBy: WebFormSubmissionsOrderByInput\n  $include: Boolean!\n  $includeSubmissions: Boolean!\n  $first: Int\n) {\n  node(id: $revisionId) @include(if: $include) {\n    __typename\n    ... on WebFormRevision {\n      id\n      __typename\n      webFormId\n      questions {\n        edges {\n          node {\n            id\n            type\n            richEditorBody\n            ordering\n            isRequired\n            options {\n              id\n              label\n            }\n          }\n        }\n      }\n      viewerSubmissions(contentUsageId: $usageId, submissionIds: $submissionIds, orderBy: $orderBy, first: $first) @include(if: $includeSubmissions) {\n        totalCount\n        edges {\n          node {\n            id\n            webFormId\n            webFormRevisionId\n            completedAt\n            durationSeconds\n            hasAnswerKey\n            hasPassed\n            answers {\n              edges {\n                node {\n                  id\n                  body\n                  selectedOptions\n                  webFormQuestionId\n                  isSkipped\n                  isCorrect\n                  result {\n                    correctIds\n                    incorrectSelections\n                  }\n                }\n              }\n            }\n            result {\n              score\n              total\n              percentage\n              correctAnswers {\n                id\n              }\n              incorrectAnswers {\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "638c8e1919bfa7ed4683580bd326e245";

export default node;
