/**
 * @generated SignedSource<<880ff6076c4416d353c291a822109264>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PollWebForm_settingsFragment$data = {
  readonly settings: {
    readonly allowSubmissionDeletion: boolean | null;
    readonly endDate: string | null;
    readonly membersSeeAllResults: boolean | null;
  };
  readonly " $fragmentType": "PollWebForm_settingsFragment";
};
export type PollWebForm_settingsFragment$key = {
  readonly " $data"?: PollWebForm_settingsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PollWebForm_settingsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PollWebForm_settingsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WebFormSettings",
      "kind": "LinkedField",
      "name": "settings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "membersSeeAllResults",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowSubmissionDeletion",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WebForm",
  "abstractKey": null
};

(node as any).hash = "10ae7b8a3475e47b38c4a500fc36fa2e";

export default node;
