import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"

/**
 * Stringifies an object's key-value pairs into an ampersand (&) separated string. If the given argument is not an object or is an empty object, it returns an empty string.
 * @param {object} params A params object.
 * @return {string} Stringified search string
 */
function stringifySearchParamsObject(
  params: unknown,
  valueSerializer?: (value: string | number | boolean) => string
) {
  return typeof params === "object" && params
    ? Object.entries(params)
        .map(([key, value]) =>
          [key, valueSerializer ? valueSerializer(value) : value].join("=")
        )
        .flat()
        .join("&")
    : ""
}

/**
 * Hook that returns query parameters in object format
 * @returns {object} Query params object
 */

function useQueryParams<Params extends Record<string, undefined | string>>() {
  const location = useLocation()

  return getQueryParamsFromUrlQueryString(location.search) as Params
}

/**
 * Converts query string into a params object (all values of the object are strings).
 * @param {string} search Url query string
 * @return {object} Query params object
 */
function getQueryParamsFromUrlQueryString(
  search: string
): Partial<Record<string, string>> {
  const pairString = search?.replace(/\?/g, "")
  let params: Record<string, undefined | string> = {}

  if (pairString) {
    const pairs = pairString.split("&").map((pair) => {
      const splitPair = pair.split("=")
      const [key, value] = [splitPair.shift()!, splitPair.join("=")]
      return [decodeURIComponent(key), decodeURIComponent(value)]
    })

    params = Object.fromEntries(pairs)
  }

  return params
}

/**
 * Converts full url into a params object (all values of the object are strings).
 * @param {string} url Full url
 * @return {object} Query params object
 */
function getQueryParamsFromUrl(url: string) {
  let search

  try {
    search = new URL(url).search
  } catch (error) {
    search = ""
  }
  return getQueryParamsFromUrlQueryString(search)
}

function generateMailToLinkFromSelectedItems<
  T extends { email?: string | null; id: string }
>(items: T[], selectedItemIds: string[], from?: string) {
  const emails = selectedItemIds
    .map((selectedItemId) => items.find((item) => item.id === selectedItemId)?.email)
    .filter(Boolean)

  return `mailto:${from || ""}?bcc=${emails.join()}`
}

/** Use history.replace to update the pathname to the provided value */
export function useReplacePathname(pathname?: string) {
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (!pathname) return
    if (pathname !== location.pathname) {
      history.replace({
        ...location,
        pathname,
      })
    }
  }, [location, pathname, history])
}

/** Set new search values in the existing search string */
function setSearchParams<T extends Record<string, string | undefined>>(
  search: string | undefined,
  setParams: T
): string {
  const params = new URLSearchParams(search)
  Object.keys(setParams).forEach((key) => {
    const param = setParams[key]
    // If param is undefined, delete it from the search params
    if (param === undefined) params.delete(key)
    // If param is a string, set it in the search params
    else params.set(key, param)
  })

  return params.toString()
}

function removeSearchParams<TQueryParam extends Partial<Record<string, string>>>(
  search: string,
  removeParams: (keyof TQueryParam)[]
): string {
  const existingQueryParams = getQueryParamsFromUrlQueryString(search)

  const keepParams = Object.keys(existingQueryParams).reduce((kP, param) => {
    if (removeParams.includes(param)) return kP
    if (existingQueryParams[param] !== undefined && existingQueryParams[param] !== null)
      kP[param] = existingQueryParams[param]!
    return kP
  }, {} as Record<string, string>)

  return setSearchParams("", keepParams)
}

/** Remove https:// or any other scheme from start of a URL */
export function removeSchemeFromUrl(url: string) {
  return url.replace(/(^\w+:|^)\/\//, "")
}

/* Returns the initial query parameters and then immediately clears the specified ones */
export function useAutoClearQueryParams<
  Params extends Record<string, string | undefined>
>(clearParams: (keyof Params)[]): Params {
  const currentParams = useQueryParams<Params>()
  const location = useLocation()
  const history = useHistory()
  const [clearedParams, setClearedParams] = useState<Partial<Params>>()
  useEffect(() => {
    if (clearParams.some((key) => currentParams[key] !== undefined)) {
      history.replace({
        search: removeSearchParams(location.search, clearParams),
      })
      setClearedParams(
        clearParams.reduce<Partial<Params>>((cleared, key) => {
          cleared[key] = currentParams[key]
          return cleared
        }, {})
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])
  return { ...currentParams, ...clearedParams }
}

export function getFaviconUrl(url: string, iconSize = 32) {
  try {
    const domain = new URL(url).hostname
    return `https://www.google.com/s2/favicons?domain=${domain}&sz=${iconSize}`
  } catch {
    return null
  }
}

export {
  generateMailToLinkFromSelectedItems,
  getQueryParamsFromUrl,
  getQueryParamsFromUrlQueryString,
  removeSearchParams,
  setSearchParams,
  stringifySearchParamsObject,
  useQueryParams,
}
