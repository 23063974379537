/**
 * @generated SignedSource<<1aa77e1c77cd1d5535a9f393b52a341c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type webFormQueryUtils_submissionFragment$data = {
  readonly answers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly body: string | null;
        readonly id: string;
        readonly isCorrect: boolean | null;
        readonly isSkipped: boolean;
        readonly result: {
          readonly correctIds: ReadonlyArray<string>;
          readonly incorrectSelections: ReadonlyArray<string>;
        } | null;
        readonly selectedOptions: ReadonlyArray<string> | null;
        readonly webFormQuestionId: string;
      };
    }>;
  };
  readonly completedAt: string | null;
  readonly durationSeconds: number | null;
  readonly hasAnswerKey: boolean;
  readonly hasPassed: boolean | null;
  readonly id: string;
  readonly result: {
    readonly correctAnswers: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly incorrectAnswers: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly percentage: number;
    readonly score: number;
    readonly total: number;
  } | null;
  readonly webFormId: string;
  readonly webFormRevisionId: string;
  readonly " $fragmentType": "webFormQueryUtils_submissionFragment";
};
export type webFormQueryUtils_submissionFragment$key = {
  readonly " $data"?: webFormQueryUtils_submissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"webFormQueryUtils_submissionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "webFormQueryUtils_submissionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "webFormId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "webFormRevisionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "durationSeconds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasAnswerKey",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPassed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WebFormAnswerNodeConnection",
      "kind": "LinkedField",
      "name": "answers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WebFormAnswerNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WebFormAnswer",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "body",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "selectedOptions",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "webFormQuestionId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isSkipped",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isCorrect",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WebFormAnswerResult",
                  "kind": "LinkedField",
                  "name": "result",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "correctIds",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "incorrectSelections",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WebFormSubmissionResult",
      "kind": "LinkedField",
      "name": "result",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "score",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "percentage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WebFormAnswer",
          "kind": "LinkedField",
          "name": "correctAnswers",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WebFormAnswer",
          "kind": "LinkedField",
          "name": "incorrectAnswers",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WebFormSubmission",
  "abstractKey": null
};
})();

(node as any).hash = "5fe29165c355dfecf460f6d82daa4f50";

export default node;
