/**
 * @generated SignedSource<<d889ac298023ab3630ac51e481d0e93c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ZoomConnectionHumanReadableUserPlanType = "basic" | "licensed" | "none" | "premium" | "unknown" | "%future added value";
export type useRefetchableZoomMeetingProvidersQuery$variables = {
  id: string;
};
export type useRefetchableZoomMeetingProvidersQuery$data = {
  readonly organization: {
    readonly id?: string;
    readonly meetingProviders?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly organization: {
            readonly hasZoomIntegration: boolean;
            readonly id: string;
          };
          readonly user: {
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarWithDetailsFragment">;
          };
          readonly zoomConnection: {
            readonly email: string | null;
            readonly firstName: string | null;
            readonly hasWebinarAddon: boolean | null;
            readonly id: string;
            readonly isConnected: boolean;
            readonly lastName: string | null;
            readonly meetingCapacity: number | null;
            readonly picUrl: string | null;
            readonly userPlanType: ZoomConnectionHumanReadableUserPlanType;
            readonly webinarCapacity: number | null;
          };
          readonly " $fragmentSpreads": FragmentRefs<"OrganizationZoomConnectionListItemFragment">;
        };
      }>;
    } | null;
  } | null;
};
export type useRefetchableZoomMeetingProvidersQuery = {
  response: useRefetchableZoomMeetingProvidersQuery$data;
  variables: useRefetchableZoomMeetingProvidersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "platform",
    "value": "zoom"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ZoomConnection",
  "kind": "LinkedField",
  "name": "zoomConnection",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "picUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userPlanType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasWebinarAddon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "meetingCapacity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "webinarCapacity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnected",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasZoomIntegration",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useRefetchableZoomMeetingProvidersQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "MeetingProviderNodeConnection",
                "kind": "LinkedField",
                "name": "meetingProviders",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MeetingProviderNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MeetingProvider",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "OrganizationZoomConnectionListItemFragment"
                          },
                          (v2/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ProfileAvatarWithDetailsFragment"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "meetingProviders(platform:\"zoom\")"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useRefetchableZoomMeetingProvidersQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "MeetingProviderNodeConnection",
                "kind": "LinkedField",
                "name": "meetingProviders",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MeetingProviderNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MeetingProvider",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "fullName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "avatar",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isTest",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "meetingProviders(platform:\"zoom\")"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dc71e2252bc58def0b886446d0b5884b",
    "id": null,
    "metadata": {},
    "name": "useRefetchableZoomMeetingProvidersQuery",
    "operationKind": "query",
    "text": "query useRefetchableZoomMeetingProvidersQuery(\n  $id: ID!\n) {\n  organization: node(id: $id) {\n    __typename\n    ... on Organization {\n      id\n      meetingProviders(platform: zoom) {\n        edges {\n          node {\n            ...OrganizationZoomConnectionListItemFragment\n            id\n            zoomConnection {\n              id\n              email\n              firstName\n              lastName\n              picUrl\n              userPlanType\n              hasWebinarAddon\n              meetingCapacity\n              webinarCapacity\n              isConnected\n            }\n            organization {\n              id\n              hasZoomIntegration\n            }\n            user {\n              id\n              ...ProfileAvatarWithDetailsFragment\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment OrganizationZoomConnectionListItemFragment on MeetingProvider {\n  id\n  zoomConnection {\n    id\n    email\n    firstName\n    lastName\n    picUrl\n    userPlanType\n    hasWebinarAddon\n    meetingCapacity\n    webinarCapacity\n    isConnected\n  }\n  organization {\n    id\n    hasZoomIntegration\n  }\n  user {\n    id\n    ...ProfileAvatarWithDetailsFragment\n  }\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n\nfragment ProfileAvatarWithDetailsFragment on User {\n  id\n  fullName\n  ...ProfileAvatarFragment\n}\n"
  }
};
})();

(node as any).hash = "60694e436e6db98223c4e31b7c9e5057";

export default node;
